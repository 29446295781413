.energy-chart--container {
  width: 100%;
  border-radius: var(--rounded-radius);
  border: var(--card-border);
  padding: 1rem;
  background-color: #ffffff;
  height: 100%;

  @media (max-width: 768px) {
    height: auto;
  }

  div[data-highcharts-chart] {
    > div:not([class*='gauge']) {
      min-width: 400px;
      width: 100% !important;

      svg {
        width: 100%;
      }
    }
  }

  .no-data {
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #3d5259;

    .illustration {
      width: 100px;
      margin-bottom: 1rem;
    }
  }

  .title {
    color: #3d5259;
    font-size: 1rem;
    font-weight: 500;
    padding: 0px;
  }

  .energy-chart--body {
    display: flex;
    align-items: center;
    // justify-content: space-between;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }

    @media (max-width: 768px) {
      height: auto;
      padding-top: 1rem;
      // justify-content: center;
    }
  }

  .energy-chart--legend {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    flex-wrap: wrap;

    .legend-item {
      display: flex;
      align-items: center;
      margin-right: 0.75rem;
      cursor: pointer;

      &.disabled {
        opacity: 0.7;
      }

      .point {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      .name {
        font-size: 12px;
        color: #536471;
        margin-left: 0.25rem;
      }
    }
  }

  .head-title {
    padding: 0px !important;
  }

  .info-block {
    &:has(div.donut) {
      padding: 0.75rem 0.5rem;
    }
  }

  .content {
    padding: 0 1rem;
    background-color: transparent !important;
    box-shadow: none;
    flex: 1;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px;

    .title,
    .subtitle {
      padding: 0px;
    }
  }

  .statistics {
    margin-left: 4px;
    display: block;

    @media (max-width: 768px) {
      margin-left: 1.5rem;
    }

    .stat {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.75rem;

      &:first-child {
        margin-top: 1.75rem;
      }

      > .icon > sl-icon {
        font-size: 2.25rem;
      }

      .value {
        font-size: 0.875rem;
        font-weight: 500;
        color: #3d5259;
        text-align: left;
        margin-left: 0.75rem;

        > span {
          margin-top: 4px;
          color: #536471;
          font-weight: 400;
          font-size: 0.75rem;
          display: block;
          white-space: nowrap;
        }
      }
    }

    .kpi-text {
      opacity: 0.6;
      color: var(--sl-text-gray);
      font-size: 0.8125rem;
    }

    .kpi {
      font-size: 1rem;
      color: #161c24;
      font-weight: 600;
    }
  }

  .energy-donut {
    min-width: 20%;
    max-width: 20%;
    max-height: 100%;
    display: flex;

    @media (max-width: 1366px) {
      max-width: 30%;
    }
    @media (max-width: 1200px) {
      flex: 1;
    }
    @media (max-width: 1024px) {
      flex: auto;
      max-width: 30%;
      min-width: 30%;
    }
    @media (max-width: 991px) {
      flex: auto;
      max-width: 75%;
      min-width: 75%;
    }

    .donut {
      width: 100%;
      max-height: 320px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .energy-column-chart {
    min-width: 65%;
    max-width: 80%;
    max-height: 100%;
    flex-grow: 1;
    flex-basis: 80%;

    @media (max-width: 1366px) {
      max-width: 70%;
    }

    @media (max-width: 1200px) {
      flex: 1;
    }
    @media (max-width: 1024px) {
      flex: auto;
      max-width: 59%;
      min-width: 59%;
    }
    @media (max-width: 991px) {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }

    .bar-chart {
      width: 100%;
      max-height: 320px;
    }
  }
}
