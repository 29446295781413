div.mask-input {
  label {
    font-size: 0.8125rem;
    font-weight: 400;
    margin-bottom: var(--sl-spacing-3x-small);
  }

  input {
    width: 100%; // Fill the container
    height: var(--sl-input-height-medium);
    border-radius: var(--sl-input-border-radius-medium);
    background-color: var(--sl-input-background-color);
    border: solid var(--sl-input-border-width) var(--sl-input-border-color);
    padding: 0 var(--sl-input-spacing-medium);
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.875rem;
    font-family: var(--sl-input-font-family);
    letter-spacing: var(--sl-input-letter-spacing);
    color: var(--sl-input-color);
    outline: none;

    &:read-only {
      border: none;
      padding: 0px;
      font-weight: var(--sl-input-font-weight);
    }

    &:not(:read-only):focus {
      background-color: var(--sl-input-background-color-focus);
      border-color: var(--sl-input-border-color-focus);
      box-shadow: 0 0 0 var(--sl-focus-ring-width) var(--sl-input-focus-ring-color);
    }
  }

  .helper-text {
    color: var(--sl-input-help-text-color);
    font-size: 0.75rem;
    margin-top: 5px;
    display: block;
  }
}
