.time-filter {
  sl-radio-button {
    --sl-color-primary-600: #f4f4f4;
    --sl-color-primary-700: #f4f4f4;
    --sl-color-neutral-700: var(--sl-text-gray);
    --sl-color-neutral-0: var(--sl-color-matte-blue);

    &.selected {
      &::part(label) {
        font-weight: 600;
      }
    }

    &::part(button) {
      border-radius: var(--rounded-radius);
      border: none;
      height: auto;
      line-height: normal;
      padding: 2px 10px;
      min-height: 0px;
    }

    &::part(label) {
      font-size: 0.6875rem;
    }
  }
}
