div.breadcrumbs {
  background-color: #eceeee;
  padding: 2px;
  border: 1px solid #d8dcde;
  font-size: 0.8125rem;
  display: flex;
  align-items: center;

  .active-crumb {
    &::part(base) {
      color: var(--sl-background-blue);
    }

    &::part(label) {
      font-weight: normal !important;
    }
  }

  sl-breadcrumb-item {
    --sl-color-neutral-600: var(--sl-color-matte-blue);
    &::part(base) {
      cursor: pointer;
      color: var(--sl-color-matte-blue);
      font-weight: 500;
    }

    sl-icon {
      font-size: 0.875rem;
    }
  }

  > sl-icon-button {
    &::part(base) {
      font-size: 0.875rem;
    }
  }

  span {
    color: var(--sl-text-gray);
  }
}
