.configure-prompt {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  sl-button {
    &::part(base) {
      background-color: var(--sl-background-blue);
    }
  }

  .configure-prompt__content {
    color: var(--sl-text-gray);
    font-size: 0.875rem;
    margin: 0 2.5rem 0 1.5rem;
    font-size: 0.875rem;

    .configure-prompt__description {
      font-size: 0.75rem;
    }
  }
}
