.action-buttons {
  display: flex;
  align-items: center;

  sl-button {
    font-size: 0.8125rem;
  }

  sl-icon {
    font-size: 1rem;
    vertical-align: -3px;
    margin-right: 0.25rem;
  }

  sl-icon-button,
  sl-button[variant='text'] {
    &::part(base) {
      color: var(--sl-background-blue);
    }
  }

  sl-button[variant='text'] {
    --sl-button-font-size-medium: 0.8125rem;
    --sl-font-weight-semibold: 500;
    --sl-input-height-small: 20px;

    &::part(base) {
      cursor: pointer;
      line-height: normal;
      min-height: 0px;
      padding: 0;
    }

    &::part(label) {
      padding-left: 0.25rem;
      padding-right: 0px;
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
