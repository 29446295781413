$border-color: #919aa366;
$cell-text-color: #536471;

.table-wrapper {
  border-radius: var(--sl-border-radius-small);
  overflow-y: auto;
  max-height: 500px;
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    position: relative;
    overflow: auto;

    th,
    td {
      color: $cell-text-color;
      font-size: 13px;
      min-width: 60px;
    }

    .selection-box {
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;

      sl-checkbox {
        &::part(label) {
          margin: 0px;
        }
      }
    }

    thead {
      border-radius: var(--sl-border-radius-small) var(--sl-border-radius-small) 0 0;
      border-bottom: 1px solid #e5e5e5;
      position: sticky;
      top: 0px;
    }

    th {
      position: sticky;
      top: 0px;
      cursor: pointer;
      user-select: none;
      font-size: 0.8125rem;
      font-weight: 400;
      padding-bottom: 0.75rem;

      > .head-col {
        display: flex;
        align-items: center;

        > sl-icon {
          margin-left: 0.5rem;
        }
      }
    }

    td {
      background-color: #ffffff;
      padding-top: 1rem;
    }
  }

  &.filled {
    table {
      border-collapse: collapse;
      border-radius: 4px;
      border: 1px solid #d8dcde;
      overflow-y: auto;
    }

    thead {
      background-color: #eceeee;
      border-radius: 4px 4px 0 0;
      // border: 1px solid #d8dcde;
    }

    th {
      padding: 11px 16px 10px;
      font-size: 0.8125rem;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        width: 100%;
        height: 1px;
        background-color: #d8dcde;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 1px;
        background-color: #d8dcde;
      }
    }

    td {
      padding: 11px 16px 10px;
      font-size: 0.8125rem;
    }

    tr {
      border: 1px solid #d8dcde;
    }
  }

  .expand-button-td {
    width: 40px;
    min-width: 40px;
    > sl-icon-button {
      &::part(base) {
        padding: 2px;
      }
    }
  }

  .table-sub-row > td {
    background-color: #f9f9f9;
    padding: 0.5rem 1rem 0.5rem 0;

    .table-wrapper {
      max-height: 200px;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0;

  .page-row-stat {
    display: flex;
    align-items: center;
    color: #353c4e;
    font-size: 0.6875rem;

    > span {
      color: #505458;
      margin-left: 1rem;
    }

    > sl-select {
      margin: 0 0.5rem;
      height: 30px !important;
      width: 50px !important;

      &::part(control) {
        height: 30px;
        min-height: 30px;
      }

      &::part(combobox) {
        padding: 0px;
        padding-right: 4px;
      }

      &::part(display-input) {
        text-align: center;
      }

      &::part(suffix),
      &::part(expand-icon) {
        display: none;
      }

      &::part(display-label) {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
      }

      &::part(listbox) {
        width: 60px !important;
        margin-left: -4px;
      }

      sl-option {
        &[aria-selected='true'] {
          &::part(checked-icon) {
            visibility: hidden;
          }
        }
      }
    }
  }

  .page-navigation {
    sl-icon {
      font-size: 1rem;
      vertical-align: middle;
    }

    .page-ellipsis {
      &::part(base) {
        border-radius: 0px;
        &:hover {
          background-color: var(--sl-color-neutral-0);
          border-color: var(--sl-color-neutral-300);
          color: var(--sl-color-neutral-700);
        }
      }
    }
  }
}
