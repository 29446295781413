$font-family: 'Poppins', sans-serif;

$drawer-width: 80px;
$ob-drawer-width: 236px;
$nav-height: 64px;
$round-radius: 8px;

$error-color: #e7431a;
$invalid-color: #e90e0e66;
$icon-color: #ffffff;
$info-color: #007dab;
$active-nav-color: #ffffff;
$active-tab-color: #0f1419;
$text-dark-gray: #161c24;
$text-gray: #505458;
$body-bg: #3d52591a;

$legacy-background-color: #09293c;
$background-blue-color: #377289;
$matte-blue-color: #3d5259;
$text-light-gray: #536471;
$primary-color-600: #377289;
$primary-color-500: #285465;
$border-color: #cfd9de;

$border-radius: var(--sl-border-radius-small);
$btn-border-radius-sm: $border-radius;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
