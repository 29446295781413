.file-upload {
  position: relative;
  text-align: left;

  #form-file-upload {
    width: 100%;
    font-size: 0.875rem;
    color: var(--sl-input-color);
  }

  .left-labelled {
    display: flex;
    align-items: center;

    label {
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
  }

  #input-file-upload {
    display: none;
  }

  .drop-zone-wrapper {
    position: relative;
  }

  .drop-zone {
    width: 100%;
    cursor: default;
    height: var(--sl-input-height-medium);
    border-radius: var(--sl-input-border-radius-medium);
    background-color: var(--sl-input-background-color);
    border: solid var(--sl-input-border-width) var(--sl-input-border-color);
    padding: 0 var(--sl-input-spacing-medium);
    display: flex;
    align-items: center;
    font-weight: var(--sl-input-font-weight);
    letter-spacing: var(--sl-input-letter-spacing);

    .drop-placeholder {
      color: var(--sl-input-help-text-color);
      font-size: 0.875rem;
    }

    &.drag-active {
      background-color: var(--sl-input-background-color-focus);
      border-color: var(--sl-input-border-color-focus);
      box-shadow: 0 0 0 var(--sl-focus-ring-width) var(--sl-input-focus-ring-color);
    }
  }

  .uploaded-file {
    height: 30px;
    border-radius: var(--sl-border-radius-small);
    border: 1px solid #e2e3e5;
    background-color: #f3f3f3;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 6px;
    white-space: nowrap;
    color: var(--sl-text-gray);

    &:not(:last-child) {
      margin-right: 0.625rem;
    }

    sl-icon {
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }

  .upload-btn {
    position: absolute;
    right: 0;
    top: 0;
  }

  .helper-text {
    color: var(--sl-input-help-text-color);
    font-size: var(--sl-input-help-text-font-size-medium);
    margin-top: 5px;
    display: block;
  }

  label {
    display: inline-block;
    color: var(--sl-input-label-color);
    margin-bottom: var(--sl-spacing-3x-small);
    font-size: 0.8125rem;
    font-weight: 400;

    p {
      margin-bottom: 0px;
    }
  }

  #label-file-upload.drag-active {
    background-color: #ffffff;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}
