.nav-button {
  padding: 8px;
  margin-top: 0.75rem;
  color: #ffffff;
  font-size: 0.6875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.active {
    background-color: #ffffff33;
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  sl-icon {
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }
}