.tag-input {
  min-width: 200px;
  width: 100%;
  position: relative;

  .sub-heading {
    color: var(--sl-text-gray);
    margin-bottom: 0px;
    font-size: 0.8125rem;
    font-weight: 400;
    margin-bottom: var(--sl-spacing-3x-small);
  }

  div.selector-list {
    position: absolute;
    z-index: 22;
    background: var(--sl-panel-background-color);
    border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
    border-radius: var(--sl-border-radius-medium);
    width: 100%;
    top: calc(var(--sl-input-height-medium) - 1px);

    > sl-menu {
      width: 100%;
      max-height: 225px;
      background: none;
      border: none;
      border-radius: none;

      sl-menu-item {
        &::part(label) {
          font-size: 0.875rem;
        }
      }
    }
  }
}

.tags-input-container {
  border-radius: var(--sl-input-border-radius-medium);
  font-size: var(--sl-input-font-size-medium);
  min-height: var(--sl-input-height-medium);
  background-color: var(--sl-input-background-color);
  border: solid var(--sl-input-border-width) var(--sl-input-border-color);
  color: var(--sl-input-color);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  font-family: var(--sl-input-font-family);
  font-weight: var(--sl-input-font-weight);
  letter-spacing: var(--sl-input-letter-spacing);
  vertical-align: middle;
  overflow: hidden;
  transition: var(--sl-transition-fast) color, var(--sl-transition-fast) border, var(--sl-transition-fast) box-shadow;
  cursor: pointer;
  position: relative;

  .expand-icon {
    background-color: var(--sl-input-background-color);
    padding: 0 1rem;
  }
}

.disabled {
  border: none;
  background-color: transparent;
  cursor: none;
}

.tags {
  width: auto;
  margin-inline-start: var(--sl-spacing-2x-small);

  sl-tag {
    margin: calc(var(--sl-spacing-2x-small) / 2) 0;
    margin-right: var(--sl-spacing-2x-small);
    font-weight: 600;
    &::part(base) {
      color: var(--sl-text-gray);
      background-color: transparent;
      border-color: #788da4;
    }
  }
}

.helper-text {
  color: #919aa3;
  font-size: 0.75rem;
  margin-top: 5px;
  display: block;
}

.tags-input {
  display: block;
  padding: 0.5rem;
  border: 1px solid #50545850;
  border-radius: 3px;
  outline: none;
  margin: 0.5rem auto;
  font-size: 0.875rem;
  color: var(--sl-input-color);
  width: calc(100% - 1rem);
}

span.place-holder {
  margin: 0 1rem 0 0.75rem;
  font-size: 0.8125rem;
  color: #919aa3;
}
