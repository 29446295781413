@import '../../../app/vars';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
$app-base-path: '~@co4/base/src';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~bootstrap/scss/bootstrap';
@import 'layout';
@import 'dashboard';
@import 'theme';
@import '@shoelace-style/shoelace/dist/themes/light.css';
