.usage-stats-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.show-horizontal {
    flex-direction: row;
  }

  &.show-vertical {
    flex-direction: column;
  }

  .usage-stats {
    .stat-key {
      color: var(--sl-text-gray);
      font-size: 0.75rem;
      display: flex;
      align-items: center;

      .circle {
        border-radius: 100%;
        width: 8px;
        height: 8px;
        margin-right: 4px;
      }
    }

    .stat-value {
      font-size: 1.25rem;
      font-weight: 500;
      color: var(--sl-color-matte-blue);

      .unit {
        font-size: 0.75rem;
      }
    }
  }
}
