.uom-input {
  --label-width: 7rem;
  --gap-width: 1rem;
  --dropdown-margin: calc(var(--label-width) + var(--gap-width));

  position: relative;

  sl-input {
    &::part(form-control-help-text) {
      margin-top: 0px;
    }

    &.label-on-left {
      & ~ sl-dropdown {
        &::part(panel) {
          margin-left: var(--dropdown-margin);
        }
      }
    }
  }

  .selector-list {
    position: absolute;
    z-index: 22;
    width: 100%;

    > sl-menu {
      width: 100%;
      max-height: 225px;

      sl-menu-item {
        &::part(label) {
          font-size: 0.875rem;
        }
      }
    }
  }
}
