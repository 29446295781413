.date-range-picker {
  display: flex;
  align-items: center;

  > sl-icon-button {
    margin: 0px !important;

    &::part(base) {
      border: none !important;
    }
  }

  .date-pickers-card {
    &::part(body) {
      display: flex;
      flex-direction: column;
    }

    .picker-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1rem;

      sl-button {
        & + sl-button {
          margin-left: 0.5rem;
        }

        &[variant='primary']::part(base) {
          color: #fff;
        }
      }

      sl-icon {
        color: var(--sl-color-warning-600);
        margin-right: 0.75rem;
        font-size: 1rem;
      }
    }
  }
}

.date-range {
  cursor: pointer ;
  padding: 0.25rem 0.5rem;
  font-size: 0.8125rem;
  font-weight: 500;
  color: var(--sl-text-gray);
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: var(--rounded-radius);
  user-select: none;

  &[aria-invalid='true'] {
    color: var(--sl-color-danger-600);
    background-color: var(--sl-color-danger-100);
  }
}
