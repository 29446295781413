.card-wrapper {
  padding: 1rem;
  background: #ffffff;
  flex: 1;

  &.bordered {
    border: var(--card-border);
    border-radius: var(--rounded-radius);
  }

  .card-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &:not([hidden]) {
      + .card-body {
        margin-top: 1rem;
      }
    }
  }

  .card-title {
    color: var(--sl-color-matte-blue);
    font-weight: 500;
    font-size: 1rem;

    sl-icon {
      margin-right: 0.5rem;
    }
  }

  .card-subtitle {
    color: var(--sl-text-gray);
    font-size: 0.8125rem;
  }
}
