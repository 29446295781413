.coming-soon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  color: var(--sl-text-gray);
  font-size: 0.875rem;

  .coming-soon__content {
    margin-top: 1rem;
    text-align: center;

    .coming-soon__title {
      font-size: 1rem;
      font-weight: 500;
      color: #3d5259;
      line-height: 2rem;
    }

    .coming-soon__description {
      font-size: 0.8125rem;
      color: #707070;
    }
  }
}
