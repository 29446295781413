$border-radius: var(--sl-border-radius-small);

.nav-tab {
  height: 100%;
  font-size: 0.8125rem;

  &.active {
    a {
      color: var(--indicator-color);
    }
  }

  sl-tooltip {
    --sl-tooltip-arrow-size: 0;
    --sl-tooltip-background-color: rgba(31, 31, 31, 0.9);
    --sl-tooltip-color: #e3e3e3;

    &::part(body) {
      text-transform: none;
      font-size: 0.8125rem;
    }
  }

  a,
  div[role='tab'] {
    color: var(--sl-text-gray);
    border-radius: $border-radius $border-radius 0 0;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;

    > sl-icon,
    > div > sl-icon,
    > div > svg,
    > svg {
      margin-right: 0.5rem;
    }

    > sl-icon,
    > div > sl-icon {
      font-size: 1.25rem;
      color: inherit;
    }

    > svg,
    > div > svg {
      width: 1.25rem;
      height: 1.25rem;
    }

    &.inactive {
      opacity: 0.5;

      > div {
        display: flex;
      }
    }

    &.switch-content div {
      display: flex;
    }

    &.disabled {
      color: var(--sl-color-neutral-500);
    }
  }
}
